@import '../../assets/styles/variables';
@import 'mdb-react-ui-kit/dist/scss/bootstrap/bootstrap';

.footer {
  background-color: $color-primary;
  color: $color-white;

  .footer-container {
    padding: 0 9rem;

    .footer-row {
      justify-content: space-between;

      .h2 {

        font-size: 1.5rem;
        font-weight: 500;

      }

      .address-col {

        .address-elem {

          display: flex;
          gap: 1.5rem;
          align-items: center;

          .icon {

            height: fit-content;
            min-width: 3rem;

            &:before {

              font-size: 2.5rem;

            }

          }

          p {

            font-size: 1.2rem;
            margin: 0;
            font-weight: 400;

            a {

              text-decoration: none;
              color: $color-white;

              &:hover {

                color: $color-secondary;

              }

            }

          }

        }

      }

      .link-col {

        display: flex;
        align-items: center;

        .list-unstyled {

          margin: 0;

          li {

            margin-bottom: 1rem;

            a {

              color: $color-white;
              text-decoration: none;
              font-size: 1.2rem;

              &:hover {

                color: $color-secondary;

              }

            }

          }

        }

      }

    }

    .copyright {

      p {
        font-size: 1rem;
        margin: 0;

        .heart-icon {
          color: $color-secondary;
        }

        a {
          color: $color-white;
          text-decoration: none;

          &:hover {

            color: $color-secondary;

          }

        }

        .divider {
          display: inline-block;
        }
      }

    }
  }

}

@include media-breakpoint-down(md) {

  .footer {
    .footer-container {

      padding: 0 2rem;

      .copyright {

        p {

          .divider {

            display: none;

          }

        }

      }

    }
  }

}